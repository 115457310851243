@use '../styles/theme' as theme;

html,
body {
  padding: 0;
  margin: 0;
  font-family: theme.$body-font;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

form {
  width: 100%;
}

label {
  display: block;
  margin: 1rem 0 0.5rem;
}

input[type="text"], input[type="tel"], input[type="email"] {
  display: block;
  padding: 1rem;
  width: 100%;
}

input[type="checkbox"] {
  display: inline;
}

button[type="submit"] {
  background: theme.$cta-color;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: block;
  font-family: theme.$body-font;
  font-size: 1rem;
  margin: 2rem 0;
  padding: 1rem;
  text-align: center;
}
